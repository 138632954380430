@import "../constants.css";
@import "../Main.css";

.main-about{
    padding: 15px;
}

.about-page-sub-head{
    font-size: 25px !important;
    color: var(--navy);
}

/* .about-page-img > img{
    height: 410px !important;
} */

/* .about-main2{
    display: flex;
    flex-direction: row;
} */

.about-main2{
    display: grid;
  padding: 50px 200px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 700px) {

    .about-main{
        padding: 0;
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr !important;
    }

    .about-right-top{
        width: 85% !important;
        margin-top: 20px !important;
    }

    .about-main2{
        display: flex !important;
        padding: 330px 0px;
        padding-bottom: 0px;
        flex-direction: column-reverse;
    }

    .main-about{
        min-height: 2000px;
    }

    .about-page-img > img{
        height: 250px !important;
    }
}

