@import "../constants.css";
@import "../Main.css";

.landing-intro {
  height: 80vh;
}

.profile-name {
  margin: 0;
  font-size: 55px;
  padding-right: 135px;
}

.profile-image > img {
  border-radius: 12px;
  height: 80%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.intro-text {
  padding: 50px;
  color: var(--navy);
}

.intro-text > p {
  width: 411px;
  height: auto;
  font-size: 24px;
  margin: 0;
}

.landing-flex {
  padding: 25px;
  padding-top: 30px;
}

.scroll-animation {
  position: relative;
  height: 80px;
  bottom: 60px;
  width: auto;
}

.profile-img {
  width: auto;
}

.scroll-right {
  left: 95%;
  bottom: 252px;
}

.sub-home {
  background: var(--biege);
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* About Section */

#about-section {
  height: 70vh;
  display: grid;
  padding: 50px 0px;
}

.about-main {
  position: static;
  display: grid;
  padding: 50px 200px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.about-left > img {
  height: 470px !important;
}

.about-text {
  font-size: 14px;
  line-height: 1.8rem;
}

.about-right-top {
  width: 80%;
}

/* 
Work Section */

.work-section {
  padding: 120px 200px;
  padding-top: 400px;
}

.work-main {
  position: static;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  margin-top: 20px;
  padding: 30px 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
}

.work-card-image > img {
  border-radius: 12px;
  margin: 20px;
}

.work-card {
  align-items: center;
}

.work-sub-heading {
  font-weight: 600;
  color: var(--navy);
}

/* Recognition section */
.recog-cards {
  padding: 40px 120px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
}

.Recognition-cards {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  padding: 40px 70px !important;
}

.Recognition-card-img {
  height: auto !important;
}

.recog-card {
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.recog-card-img > img {
  border-radius: 12px;
  height: 100px;
}

.recog-card-data {
  padding: 10px;
  padding-top: 0px;
  text-align: center;
}

.speaker-image > img {
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 150px;
}

.speaker-cards {
  row-gap: 25px;
}

/* Recordings */

.youtubecard {
  gap: 10px;
  grid-template-columns: 1fr 1fr !important;
}

.youtube-card {
  height: 360px;
  width: 640px;
}

.youtube {
  height: 100%;
  width: 100%;
}

/* Footer */

#footer {
  margin-top: 80px;
  background-color: var(--navy);
  height: 100vh;
  font-family: var(--bodyfont);
}

.social-links {
  margin-top: 40px !important;
  padding: 0;
  margin: 0;
}

.social-media > a {
  color: #fff;
}

.social-icon {
  font-size: 30px !important;
  margin: 10px;
  transition: transform 0.3s ease-out;
}

.social-icon:hover {
  cursor: pointer;
  color: var(--sea);
  transform: scale(1.5);
  transition: transform 0.1s;
}

.footer-heading {
  color: #fff !important;
  font-size: 55px;
  margin-top: 100px;
}

#speaker-section {
  padding: 80px 50px;
}

.name-field {
  margin-right: 5px;
}

.back-to-top{
  position: fixed;
  bottom: 25px;
  right: 25px;
}

.top-button {
  padding: 10px;
  background-color: var(--sea);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-align: center;
  z-index: 20;
  transition: all 0.5s ease-in-out;
}



.shapedividers_com-4464 {
  overflow: hidden;
  position: relative;
}
.shapedividers_com-4464::before {
  content: "";
  font-family: "shape divider from ShapeDividers.com";
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 244% 38px;
  background-position: 2% 0%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" fill="%23ffffff"/></svg>');
}

@media (min-width: 768px) {
  .shapedividers_com-4464::before {
    background-size: 285% 115px;
    background-position: 40% 0%;
  }
}

@media (min-width: 1025px) {
  .shapedividers_com-4464::before {
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw;
    background-size: 200% 115px;
    background-position: 40% 0%;
  }
}
@media (min-width: 2100px) {
  .shapedividers_com-4464::before {
    background-size: 200% calc(2vw + 115px);
  }
}

@media screen and (max-width: 700px) {
  .flex-mobile > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 35px;
  }
  .flex-mobile > p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  .recog-cards {
    grid-template-columns: 1fr !important;
    padding: 9px !important;
  }
  .about-mobile {
    grid-template-rows: 1fr 1fr !important;
    grid-template-columns: none !important;
    padding: 50px 0px !important;
    align-items: end;
  }
  .about-main {
    /* height: 840px; */
    height: 770px;
  }
  .about-right-top {
    margin-top: 30px;
    width: 90%;
  }
  .content > p {
    width: 100%;
  }
  .work-card {
    flex-direction: column;
    text-align: center;
  }
  .work-section {
    padding: 800px 30px;
    padding-bottom: 60px;
  }
  .youtubecard {
    grid-template-columns: 1fr !important;
    padding: 0 !important;
    padding: 35px 0 !important;
  }
  .about-left > img {
    height: 320px !important;
  }
  .about-left {
    height: 300px !important;
  }
  .press-cards {
    padding: 45px !important;
  }

  .youtube-card {
    width: 60%;
    height: 100%;
  }
  .speaker-cards {
    grid-template-columns: 1fr 1fr !important;
    padding: 10px !important;
    gap: 15px;
  }
  #speaker-section {
    padding: 0 11px !important;
  }
  .intro-text > p {
    padding: 0 10px;
    text-align: center;
    width: 100%;
  }
  .intro-text {
    padding: 0;
  }
  .landing-flex {
    padding: 0;
  }
  .work-main {
    padding: 20px;
  }
  #recordings {
    margin-top: 40px;
  }
  .profile-image {
    /* height: 470px; */
    height: 398px;
    width: auto;
  }
  #about-section {
    padding: 0;
  }
  .work-card-image > img {
    height: 150px;
    margin: 10px;
  }
  .input-form {
    flex-direction: column;
  }
  #footer {
    height: 800px;
  }
  .footer-heading {
    margin-top: 55px;
    margin-bottom: 20px;
    font-size: 45px;
  }
  .contact-form {
    width: 100%;
  }
  .name-field {
    width: 100%;
    margin-right: 0;
  }
  .social-icon {
    font-size: 25px !important;
    margin: 8px;
  }
  .scroll-animation {
    bottom: -15px;
  }
}
