@import '../constants.css';

.navbar-custom{
    background-color: var(--biege) !important;
    padding: 15px 150px;
    color: var(--navy) !important;
    margin: 20px 40px 0 80px;
    border-radius: 20px;
    width: 90% !important;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}

.nav-brand{
    font-size: 50px;
}

.nav-button{
    text-transform: none !important;
    padding: 6px 20px !important;
    color: var(--navy) !important;
    font-family: var(--bodyfont) !important;
}

.nav-button:hover{
    background: none !important;
}

@media screen and (max-width: 700px){
    .navbar-custom{
        margin: 0;
        width: 100% !important;
        border-radius: 0;
        box-shadow: none !important;
        padding: 10px 0;
    }
}

