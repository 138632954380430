:root {
    --biege: #F3F3E0;
    --navy: #133E87;
    --blue: #608BC1;
    --sea: #CBDCEB;

    --black: #000000;
    --white: #ffffff;

    --bodyfont: 'Quicksand', sans-serif;
    --headfont: 'Montserrat', sans-serif;
    --font3: 'Caramel', cursive;
}
