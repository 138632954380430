@import "./constants.css";

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--bodyfont);
}

.flex-custom {
  display: flex;
}

.center-flex {
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.quicksand {
  font-family: var(--bodyfont);
}

.montserrat {
  font-family: var(--headfont);
}

.caramel {
  font-family: var(--font3);
}

.body-color-blue {
  color: var(--blue) !important;
}

.body-color-navy {
  color: var(--navy) !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.sub-headings {
  font-weight: 300;
  font-size: 30px;
  display: flex;
  justify-content: center;
  color: var(--navy);
}

.sub-headings > h2 {
  margin: 0 !important;
}

.horizontal {
  border: 1px solid var(--sea);
}

.none-hr {
  border: none;
  height: 0;
  margin: 0;
}

.button-style {
  appearance: none;
  background-color: var(--sea);
  border: none;
  border-radius: 12px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button-style:disabled {
  pointer-events: none;
}

.button-style:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: translateY(-2px);
}

.button-style:active {
  box-shadow: none;
  transform: translateY(0);
}

.gallery-box {
  padding: 30px 120px !important;
}

.content > p{
    text-align: justify;
}

/* Locomotive Scrolling */
html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}
[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

@media screen and (max-width: 700px) {
  .flex-column {
    flex-direction: column;
  }

  .sub-home {
    flex-direction: column;
    width: 100%;
    border-radius: 0;
  }

  .gallery-box {
    padding: 15px !important;
  }
}
